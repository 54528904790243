const clerkDebtorRoutes = {
  path: "/employee",
  component: () => import("@/layouts/AuthorizedEmployeeLayout.vue"),
  children: [
    {
      path: "dashboard",
      name: "private-dashboard",
      component: () => import("../views/employee/PrivateDashboard.vue"),
    },
    {
      path: "my-cases",
      name: "my-cases",
      component: () => import("../views/employee/MyCases.vue"),
    },
    {
      path: "add-case",
      name: "add-case",
      component: () => import("../views/employee/AddCase.vue"),
    },
    {
      path: "edit-case/:id",
      name: "edit-case",
      meta: {
        cache: true
      },
      component: () => import("../views/employee/EditCase.vue"),
    },
    {
      path: "edit-case-employee/:id",
      name: "edit-case-employee",
      meta: {
        cache: true
      },
      component: () => import("../views/employee/EditCaseEmployee.vue"),
    },
    {
      path: "add-case-date/:id",
      name: "add-case-date",
      meta: {
        cache: true
      },
      component: () => import("../views/employee/AddCaseDate.vue"),
    },
    {
      path: "case/:id",
      name: "view-case",
      meta: {
        cache: true
      },
      component: () => import("../views/employee/ViewCase.vue"),
    },
    {
      path: "case/:id/wvp-case",
      name: "view-wvp-case",
      component: () => import("../views/employee/ViewWvpCase.vue"),
    },
  ],
  meta: {
    requiresAuth: true,
    role: "clerk",
  },
};

const clerkCorporateRoutes = {
  path: "/employee/corporate",
  component: () => import("@/layouts/AuthorizedEmployeeLayout.vue"),
  children: [
    {
      path: "dashboard",
      name: "corporate-dashboard",
      component: () => import("../views/employee/CorporateDashboard.vue"),
    },
    {
      path: "add-case",
      name: "corporate-add-case",
      component: () => import("../views/employee/AddCorporateCase.vue"),
    },
    {
      path: "edit-case/:id",
      name: "corporate-edit-case",
      meta: {
        cache: true
      },
      component: () => import("../views/employee/EditCorporateCase.vue"),
    },
    {
      path: "edit-case-employee/:id",
      name: "corporate-edit-case-employee",
      meta: {
        cache: true
      },
      component: () => import("../views/employee/EditCorporateCaseEmployee.vue"),
    },
    {
      path: "case/:id",
      name: "corporate-view-case",
      meta: {
        cache: true
      },
      component: () => import("../views/employee/ViewCorporateCase.vue"),
    },
  ],
  meta: {
    requiresAuth: true,
    role: "clerk",
  },
};

export { clerkDebtorRoutes, clerkCorporateRoutes };
