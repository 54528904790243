const debtorRoutes = {
  path: "/",
  component: () => import("@/layouts/AuthorizedClientLayout.vue"),
  children: [
    {
      path: "/home",
      name: "home",
      component: () => import("@/views/HomeView.vue"),
      meta: {
        requiresAuth: true,
        requiresInternalValidation: false,
        next: "general-data",
      },
    },
    {
      path: "/general-data",
      name: "general-data",
      component: () => import("@/views/GeneralData.vue"),
      meta: {
        requiresAuth: true,
        requiresInternalValidation: true,
        previous: "home",
        next: "marital-data",
      },
    },
    {
      path: "/marital-data",
      name: "marital-data",
      component: () => import("@/views/MaritalData.vue"),
      meta: {
        requiresAuth: true,
        requiresInternalValidation: false,
        requiresInteraction: true,
        previous: "general-data",
        next: "job-data",
      },
    },
    {
      path: "/job-data",
      name: "job-data",
      component: () => import("@/views/JobData.vue"),
      meta: {
        requiresAuth: true,
        requiresInternalValidation: true,
        requiresInteraction: true,
        previous: "marital-data",
        next: "selfemployment-data",
      },
    },
    {
      path: "/selfemployment-data",
      name: "selfemployment-data",
      component: () => import("@/views/SelfemploymentData.vue"),
      meta: {
        requiresAuth: true,
        requiresInternalValidation: true,
        requiresInteraction: true,
        previous: "job-data",
        next: "income-data",
      },
    },
    {
      path: "/income-data",
      name: "income-data",
      component: () => import("@/views/IncomeData.vue"),
      meta: {
        requiresAuth: true,
        requiresInternalValidation: false,
        requiresInteraction: true,
        previous: "selfemployment-data",
        next: "asset-data",
      },
    },
    {
      path: "/asset-data",
      name: "asset-data",
      component: () => import("@/views/AssetData.vue"),
      meta: {
        requiresAuth: true,
        requiresInternalValidation: false,
        requiresInteraction: true,
        previous: "income-data",
        next: "tax-data",
      },
    },
    {
      path: "/tax-data",
      name: "tax-data",
      component: () => import("@/views/TaxData.vue"),
      meta: {
        requiresAuth: true,
        previous: "asset-data",
        next: "insolvency-challenge",
      },
    },
    {
      path: "/insolvency-challenge",
      name: "insolvency-challenge",
      component: () => import("@/views/InsolvencyChallenge.vue"),
      meta: {
        requiresAuth: true,
        previous: "tax-data",
        next: "overview",
      },
    },
    {
      path: "/overview",
      name: "overview",
      component: () => import("@/views/OverView.vue"),
      meta: {
        requiresAuth: true,
        requiresInternalValidation: true,
        previous: "insolvency-challenge",
        next: null,
      },
    },
  ],
  meta: {
    requiresAuth: true,
    role: "debtor",
  },
};

const debtorWvpRoutes = {
  path: "/wvp",
  component: () => import("@/layouts/AuthorizedClientLayoutWVP.vue"),
  children: [
    {
      path: "home",
      name: "wvp-home",
      component: () => import("@/views/wvp/Home.vue"),
      meta: {
        requiresAuth: true,
        requiresInternalValidation: false,
        next: "wvp-general-data",
      },
    },
    {
      path: "general-data",
      name: "wvp-general-data",
      component: () => import("@/views/wvp/GeneralData.vue"),
      meta: {
        requiresAuth: true,
        requiresInternalValidation: true,
        previous: "wvp-home",
        next: "wvp-marital-data",
      },
    },
    {
      path: "marital-data",
      name: "wvp-marital-data",
      component: () => import("@/views/wvp/MaritalData.vue"),
      meta: {
        requiresAuth: true,
        requiresInternalValidation: true,
        requiresInteraction: true,
        previous: "wvp-general-data",
        next: "wvp-income-data",
      },
    },
    {
      path: "income-data",
      name: "wvp-income-data",
      component: () => import("@/views/wvp/IncomeData.vue"),
      meta: {
        requiresAuth: true,
        requiresInternalValidation: true,
        requiresInteraction: false,
        previous: "wvp-marital-data",
        next: "wvp-selfemployment-data",
      },
    },
    {
      path: "selfemployment-data",
      name: "wvp-selfemployment-data",
      component: () => import("@/views/wvp/SelfemploymentData.vue"),
      meta: {
        requiresAuth: true,
        requiresInternalValidation: true,
        requiresInteraction: false,
        previous: "wvp-income-data",
        next: "wvp-heritage-data",
      },
    },
    {
      path: "heritage-data",
      name: "wvp-heritage-data",
      component: () => import("@/views/wvp/HeritageData.vue"),
      meta: {
        requiresAuth: true,
        requiresInternalValidation: true,
        requiresInteraction: false,
        previous: "wvp-selfemployment-data",
        next: "wvp-winning-data",
      },
    },
    {
      path: "winning-data",
      name: "wvp-winning-data",
      component: () => import("@/views/wvp/WinningData.vue"),
      meta: {
        requiresAuth: true,
        requiresInternalValidation: true,
        requiresInteraction: false,
        previous: "wvp-heritage-data",
        next: "wvp-overview",
      },
    },
    {
      path: "overview",
      name: "wvp-overview",
      component: () => import("@/views/wvp/OverView.vue"),
      meta: {
        requiresAuth: true,
        previous: "wvp-heritage-data",
        next: null,
      },
    },
  ],
  meta: {
    requiresAuth: true,
    role: "debtor",
  },
};

export { debtorRoutes, debtorWvpRoutes };
