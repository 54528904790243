const corporateRoutes = {
  path: "/corporate",
  component: () => import("@/layouts/AuthorizedCorporateLayout.vue"),
  children: [
    {
      path: "start",
      name: "corporate-entry",
      component: () => import("../views/corporate/Start.vue"),
      meta: {
        requiresAuth: true,
        requiresInternalValidation: false,
        next: "managing-director",
      },
    },
    {
      path: "managing-director",
      name: "managing-director",
      component: () => import("../views/corporate/ManagingDirector.vue"),
      meta: {
        requiresAuth: true,
        requiresInternalValidation: false,
        previous: "start",
        next: "company",
      },
    },
    {
      path: "company",
      name: "company",
      component: () => import("../views/corporate/Company.vue"),
      meta: {
        requiresAuth: true,
        requiresInternalValidation: false,
        previous: "managing-director",
        next: "company-legal",
      },
    },
    {
      path: "company-legal",
      name: "company-legal",
      component: () => import("../views/corporate/CompanyLegal.vue"),
      meta: {
        requiresAuth: true,
        requiresInternalValidation: false,
        previous: "company",
        next: "tax-legal",
      },
    },
    {
      path: "tax-legal",
      name: "tax-legal",
      component: () => import("../views/corporate/TaxLegal.vue"),
      meta: {
        requiresAuth: true,
        requiresInternalValidation: false,
        previous: "company-legal",
        next: "accounting",
      },
    },
    {
      path: "accounting",
      name: "accounting",
      component: () => import("../views/corporate/Accounting.vue"),
      meta: {
        requiresAuth: true,
        requiresInternalValidation: false,
        previous: "tax-legal",
        next: "common",
      },
    },
    {
      path: "common",
      name: "common",
      component: () => import("../views/corporate/Common.vue"),
      meta: {
        requiresAuth: true,
        requiresInternalValidation: false,
        previous: "accounting",
        next: "corp-overview",
      },
    },
    {
      path: "overview",
      name: "corp-overview",
      component: () => import("../views/corporate/Overview.vue"),
      meta: {
        requiresAuth: true,
        requiresInternalValidation: false,
        previous: "common",
        next: null,
      },
    },
  ],
  meta: {
    requiresAuth: true,
    role: "corp_debtor",
  },
};

export { corporateRoutes };
