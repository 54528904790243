import store from "@/store/index";
import router from "@/router/index.js";

const TIMEOUT = 1000000;

const bearerInterceptor = (config) => {
  const token = sessionStorage.getItem("token");
  if (token) {
    if (!config.headers) {
      config.headers = {};
    }
    config.headers.Authorization = `Bearer ${token}`;
  }
  config.timeout = TIMEOUT;
  return config;
};

const unAuthenticated = async (error) => {
  console.log(error);
  const url = error.response?.config?.url;
  const status = error?.status || error?.response?.status;
  if (status === 401) {
    // Store logged out state.
    // store.commit("account/logout");
    if (!url.endsWith("authenticate")) {
      // Ask for a new authentication
      router.push({ name: "login" });
      // loginService.openLogin();
      return;
    }
  }

  return Promise.reject(error);
};

const authenticated = (response) => {
  return response;
};

export { bearerInterceptor, unAuthenticated, authenticated };
